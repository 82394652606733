import React from 'react';
import Carousel from 'react-bootstrap/lib/Carousel';
import { PhotoSwipe } from 'react-photoswipe';
import Img from 'gatsby-image';

import 'react-photoswipe/lib/photoswipe.css';

import Layout from '../layouts/index';

const gallerie = [
  {
    _slug: 'home3',
    group: 'Startseite',
    title: '/samples/gallery_images/home3/home3.jpg',
  },
  {
    _slug: 'patentierungsofen_1965-71',
    text: 'Patentierungsofen 1965-70',
    width: 900,
    height: 619,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/patentierungsofen_1965-71/Patentierungsofen_1965-70.jpg',
  },
  {
    _slug: 'ansicht_bergisch_gladbacher_str_dot_firmengel_nde',
    text: 'Ansicht Bergisch Gladbacher Str. Firmengelände',
    width: 900,
    height: 697,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/ansicht_bergisch_gladbacher_str_dot_firmengel_nde/Ansicht_Bergisch_Gladbacher_Str._Firmengel_nde.jpg',
  },
  {
    _slug: 'home2',
    group: 'Startseite',
    title: '/samples/gallery_images/home2/home2.jpg',
  },
  {
    _slug: 'home0',
    group: 'Startseite',
    title: '/samples/gallery_images/home0/home0.jpg',
  },
  {
    _slug: 'drahtdurchlauftrockenofen_1980-86',
    text: 'Drahtdurchlauftrockenofen 1980-85',
    width: 900,
    height: 916,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/drahtdurchlauftrockenofen_1980-86/Drahtdurchlauftrockenofen_1980-85.jpg',
  },
  {
    _slug: 'home6',
    group: 'Startseite',
    title: '/samples/gallery_images/home6/home6.jpg',
  },
  {
    _slug: 'drahtdurchlauftrockenofen_indirekte_heizung',
    text: 'Drahtdurchlauftrockenofen indirekte Heizung',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/drahtdurchlauftrockenofen_indirekte_heizung/Drahtdurchlauftrockenofen_indirekte_Heizung.jpg',
  },
  {
    _slug: 'home5',
    group: 'Startseite',
    title: '/samples/gallery_images/home5/home5.jpg',
  },
  {
    _slug: 'ehemaliges_haupthaus',
    text: 'Ehemaliges Haupthaus',
    width: 900,
    height: 641,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/ehemaliges_haupthaus/ehemaliges_Haupthaus.jpg',
  },
  {
    _slug: 'home4',
    group: 'Startseite',
    title: '/samples/gallery_images/home4/home4.jpg',
  },
  {
    _slug: 'einsatzofen_vor_1950-1',
    text: 'Einsatzofen vor 1950',
    width: 900,
    height: 724,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/einsatzofen_vor_1950-1/Einsatzofen_vor_1950.jpg',
  },
  {
    _slug: 'fa_trockner_f_r_dellbr_cker_embalagen_ca_dot_1980',
    text: 'Faßtrockner für Dellbrücker Embalagen ca. 1980',
    width: 900,
    height: 583,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/fa_trockner_f_r_dellbr_cker_embalagen_ca_dot_1980/Fa_trockner_f_r_Dellbr_cker_Embalagen_ca._1980.jpg',
  },
  {
    _slug: 'gl_hofen_f_r_eisenteile-1',
    text: 'Glühofen für Eisenteile',
    width: 900,
    height: 1043,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/gl_hofen_f_r_eisenteile-1/Gl_hofen_f_r_Eisenteile.jpg',
  },
  {
    _slug: 'gl_hofen_mit_generatordraht_vor_1950',
    text: 'Glühofen mit Generatordraht vor 1950',
    width: 900,
    height: 557,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/gl_hofen_mit_generatordraht_vor_1950/Gl_hofen_mit_Generatordraht_vor_1950.jpg',
  },
  {
    _slug: 'kohlebeheizter_patentierungsofen_vor_1950',
    text: 'Kohlebeheizter Patentierungsofen vor 1950',
    width: 900,
    height: 631,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/kohlebeheizter_patentierungsofen_vor_1950/kohlebeheizter_Patentierungsofen_vor_1950.jpg',
  },
  {
    _slug: 'lackeinbrennofen_indirekt_gasbeheizt_1960',
    text: 'Lackeinbrennofen indirekt gasbeheizt 1960',
    width: 900,
    height: 592,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/lackeinbrennofen_indirekt_gasbeheizt_1960/Lackeinbrennofen_indirekt_gasbeheizt_1960.jpg',
  },
  {
    _slug: 'luftaufnahme_firmengel_nde_ca_dot_1950',
    text: 'Luftaufnahme Firmengelände ca. 1950',
    width: 900,
    height: 827,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/luftaufnahme_firmengel_nde_ca_dot_1950/Luftaufnahme_Firmengel_nde_ca._1950.jpg',
  },
  {
    _slug: 'luftaufnahme_frimengel_nde_ca_dot_1950',
    text: 'Luftaufnahme Frimengelände ca. 1950',
    width: 900,
    height: 433,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/luftaufnahme_frimengel_nde_ca_dot_1950/Luftaufnahme_Frimengel_nde_ca._1950.jpg',
  },
  {
    _slug: 'meisterbude_schlosserei',
    text: 'Meisterbude Schlosserei',
    width: 900,
    height: 697,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/meisterbude_schlosserei/Meisterbude_Schlosserei.jpg',
  },
  {
    _slug: 'meisterb_ro_und_alte_schlosserei',
    text: 'Meisterbüro und alte Schlosserei',
    width: 900,
    height: 674,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/meisterb_ro_und_alte_schlosserei/Meisterb_ro_und_alte_Schlosserei.jpg',
  },
  {
    _slug: 'mo_hl_14',
    group: 'Startseite',
    title: '/samples/gallery_images/mo_hl_14/Mo__hl_14_small.jpg',
  },
  {
    _slug: 'm_hlstra_e_22',
    text: 'Möhlstraße 22',
    width: 900,
    height: 1254,
    group: 'Unternehmensgeschichte 2',
    title: '/samples/gallery_images/m_hlstra_e_22/M_hlstra_e_22.jpg',
  },
  {
    _slug: 'bild3_rotiert',
    group: 'Unternehmensgeschichte',
    title: '/samples/gallery_images/bild3_rotiert/Bild3_rotiert.jpg',
  },
  {
    _slug: 'labscheidbad_vor_1950',
    text: 'Ölabscheidbad vor 1950',
    width: 900,
    height: 608,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/labscheidbad_vor_1950/_labscheidbad_vor_1950.jpg',
  },
  {
    _slug: 'ansicht_berg_punkt_glad_punkt_str_punkt_firmengel_nde',
    group: 'Unternehmensgeschichte',
    title:
      '/samples/gallery_images/ansicht_berg_punkt_glad_punkt_str_punkt_firmengel_nde/Ansicht_Berg._Glad._Str._Firmengel_nde.jpg',
  },
  {
    _slug: 'packgrube_topfgl_h_fen-1',
    text: 'Packgrube Topfglühöfen',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/packgrube_topfgl_h_fen-1/Packgrube_Topfgl_h_fen.jpg',
  },
  {
    _slug: 'meisterb_ro_u_punkt_alte_schlosserei',
    group: 'Unternehmensgeschichte',
    title:
      '/samples/gallery_images/meisterb_ro_u_punkt_alte_schlosserei/Meisterb_ro_u._alte_Schlosserei.jpg',
  },
  {
    _slug: 'patentierungsofen_indirket_beheizt_ca_dot_1960',
    text: 'Patentierungsofen indirket beheizt ca. 1960',
    width: 900,
    height: 730,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/patentierungsofen_indirket_beheizt_ca_dot_1960/Patentierungsofen_indirket_beheizt_ca._1960.jpg',
  },
  {
    _slug: 'prallm_hle_ca_punkt_1950',
    group: 'Unternehmensgeschichte',
    title:
      '/samples/gallery_images/prallm_hle_ca_punkt_1950/Prallm_hle_ca._1950_small.jpg',
  },
  {
    _slug: 'prallm_hle_ca_dot_1950',
    text: 'Prallmühle ca. 1950',
    width: 900,
    height: 672,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/prallm_hle_ca_dot_1950/Prallm_hle_ca._1950.jpg',
  },
  {
    _slug: 'schaltschrank_einer_patentierungsanlage',
    text: 'Schaltschrank einer Patentierungsanlage',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/schaltschrank_einer_patentierungsanlage/Schaltschrank_einer_Patentierungsanlage.jpg',
  },
  {
    _slug: 'schaltschrank_patentierungsanlage',
    text: 'Schaltschrank Patentierungsanlage',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/schaltschrank_patentierungsanlage/Schaltschrank_Patentierungsanlage.jpg',
  },
  {
    _slug: 'schaltschrank_topfgl_h_fen',
    text: 'Schaltschrank Topfglühöfen',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/schaltschrank_topfgl_h_fen/Schaltschrank_Topfgl_h_fen.jpg',
  },
  {
    _slug: 'stangengl_hofen_gasbeheizt_1965',
    text: 'Stangenglühofen gasbeheizt 1965',
    width: 900,
    height: 540,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/stangengl_hofen_gasbeheizt_1965/Stangengl_hofen_gasbeheizt_1965.jpg',
  },
  {
    _slug: 'topfgl_hanlage_ca_dot_1970',
    text: 'Topfglühanlage ca. 1970',
    width: 900,
    height: 615,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/topfgl_hanlage_ca_dot_1970/Topfgl_hanlage_ca._1970.jpg',
  },
  {
    _slug: 'topfgl_h_fen_in_reihe_gebaut',
    text: 'Topfglühöfen in Reihe gebaut',
    width: 900,
    height: 744,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/topfgl_h_fen_in_reihe_gebaut/Topfgl_h_fen_in_Reihe_gebaut.jpg',
  },
  {
    _slug: 'topfgl_h_fen_mit_vorw_rmkammern_1980',
    text: 'Topfglühöfen mit Vorwärmkammern 1980',
    width: 900,
    height: 604,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/topfgl_h_fen_mit_vorw_rmkammern_1980/Topfgl_h_fen_mit_Vorw_rmkammern_1980.jpg',
  },
  {
    _slug: 'topfgl_h_fen',
    text: 'Topfglühöfen',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title: '/samples/gallery_images/topfgl_h_fen/Topfgl_h_fen.jpg',
  },
  {
    _slug: 'topfgl_h_ffen_gasbeheizt_1975-85',
    text: 'Topfglühöfen gasbeheizt 1975-85',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/topfgl_h_ffen_gasbeheizt_1975-85/Topfgl_h_ffen_gasbeheizt_1975-85.jpg',
  },
  {
    _slug: 'trockenofen_1975',
    text: 'Trockenofen 1975',
    width: 900,
    height: 1102,
    group: 'Unternehmensgeschichte 2',
    title: '/samples/gallery_images/trockenofen_1975/Trockenofen_1975.jpg',
  },
  {
    _slug: 'verbrennungsofen',
    text: 'Verbrennungsofen',
    width: 900,
    height: 900,
    group: 'Unternehmensgeschichte 2',
    title: '/samples/gallery_images/verbrennungsofen/Verbrennungsofen.jpg',
  },
  {
    _slug: 'verzinkungsofen_f_r_dr_hte_1970',
    text: 'Verzinkungsofen für Drähte 1970',
    width: 900,
    height: 399,
    group: 'Unternehmensgeschichte 2',
    title:
      '/samples/gallery_images/verzinkungsofen_f_r_dr_hte_1970/Verzinkungsofen_f_r_Dr_hte_1970.jpg',
  },
];

const milestones = [
  {
    _slug:
      'die-firma-moehl-und-co-punkt-fabrik-feuerfester-erzeugnisse-wird-in-muelheim-a-rh-heute-koeln-muelheim-von-hugo-moehl-1831-1903-gegruendet',
    date: 1856,
    showbadge: true,
    text:
      'Die Firma Möhl & Co., Fabrik feuerfester Erzeugnisse wird in Mülheim a. Rh. (heute Köln-Mülheim) von Hugo Möhl (1831-1903) gegründet.',
  },
  {
    _slug:
      'die-soehne-des-gruenders-rudolf-moehl-1865-1920-und-julius-moehl-1868-1934-treten-in-das-unternehmen-ein',
    date: 1890,
    showbadge: false,
    text:
      'Die Söhne des Gründers, Rudolf Möhl (1865-1920) und Julius Möhl (1868-1934) treten in das Unternehmen ein.',
  },
  {
    _slug:
      'umzug-des-unternehmens-von-muelheim-a-rh-nach-dellbrueck-durch-den-umzug-wurde-eine-wichtige-voraussetzung-fuer-die-folgende-betriebs-erweiterung-in-form-der-produktion-von-industrieoefen-fuer-die-drahtindustrie-geschaffen',
    date: 1900,
    showbadge: true,
    text:
      'Umzug des Unternehmens von Mülheim a. Rh. nach Dellbrück. Durch den Umzug wurde eine wichtige Voraussetzung für die folgende Betriebserweiterung, in Form der Produktion von Industrieöfen für die Drahtindustrie, geschaffen.',
  },
  {
    _slug:
      'nach-der-eingemeindung-mit-koeln-umbenennung-der-von-der-firma-moehl-errichteten-franziskastrasse-in-moehlstrasse',
    date: 1914,
    showbadge: false,
    text:
      'Nach der Eingemeindung mit Köln Umbenennung der von der Firma Möhl errichteten Franziskastraße in Möhlstraße.',
  },
  {
    _slug: 'dr-kurt-moehl-der-sohn-von-julius-moehl-tritt-in-die-firma-ein',
    date: 1946,
    showbadge: false,
    text: 'Dr. Kurt Möhl, der Sohn von Julius Möhl tritt in die Firma ein.',
  },
  {
    _slug: 'moehl-und-co-punkt-kg-feiert-100-jaehriges-firmenjubilaeum',
    date: 1956,
    showbadge: true,
    text: 'Möhl & Co., KG. feiert 100-jähriges Firmenjubiläum.',
  },
  {
    _slug:
      'helmut-moehl-folgt-seinem-vater-in-vierter-generation-in-der-unternehmensfuehrung-nach',
    date: 1972,
    showbadge: false,
    text:
      'Helmut Möhl folgt seinem Vater in vierter Generation in der Unternehmensführung nach.',
  },
  {
    _slug:
      'aus-diversifikationsgruenden-neubau-eines-supermarktes-auf-einem-firmengrundstueck-in-den-folgenden-jahren-der-bau-von-weiteren-gewerbe-und-wohnimmobilien',
    date: 1976,
    showbadge: false,
    text:
      'Aus Diversifikationsgründen Neubau eines Supermarktes auf einem Firmengrundstück. In den folgenden Jahren der Bau von weiteren Gewerbe- und Wohnimmobilien.',
  },
  {
    _slug:
      'eroeffnung-einer-der-ersten-squaschanlagen-deutschlands-in-koeln-dellbrueck',
    date: 1977,
    showbadge: true,
    text:
      'Eröffnung einer der ersten Squashanlagen Deutschlands in Köln-Dellbrück.',
  },
  {
    _slug: 'verkauf-der-firmensparte-industrieofenbau-an-die-loi-in-essen',
    date: 1996,
    showbadge: false,
    text: 'Verkauf der Firmensparte Industrieofenbau.',
  },
  {
    _slug:
      'sigrid-moehl-wedekind-uebernimmt-nach-dem-tod-ihres-ehemannes-helmut-moehl-die-geschaeftsfuehrung-des-unternehmens',
    date: 2002,
    showbadge: false,
    text:
      'Sigrid Möhl-Wedekind übernimmt nach dem Tod Ihres Ehemannes, Helmut Möhl die Geschäftsführung des Unternehmens.',
  },
  {
    _slug: 'umstrukturierungsprozess-hin-zu-einer-reinen-immobilienverwaltung',
    date: 2003,
    showbadge: false,
    text: 'Umstrukturierungsprozess hin zu einer reinen Immobilienverwaltung.',
  },
  {
    _slug: 'moehl-gmbh-und-co-kg-feiert-150-jaehriges-firmenjubilaeum',
    date: 2006,
    showbadge: true,
    text: 'Möhl GmbH & Co. KG feiert 150-jähriges Firmenjubiläum.',
  },
  {
    _slug:
      'mit-frederik-moehl-tritt-die-fuenfte-generation-in-die-geschaeftsfuehrung-der-firma-ein-und-konzentriert-sich-auf-die-verwaltung-und-projektierung-der-bestandsimmobilien',
    date: 2010,
    showbadge: false,
    text:
      'Mit Frederik Möhl tritt die fünfte Generation in die Geschäftsführung der Firma ein und konzentriert sich auf die Verwaltung und Projektierung der Bestandsimmobilien.',
  },
  {
    text: 'Start der Projektentwicklung des „Möhl-Areals“ in Köln-Dellbrück',
    _slug: 'start-der-projektentwicklung-des-mohl-areals-in-koln-dellbruck',
    date: 2020,
    showbadge: true
  },
  {
    text: 'Nach 20 Jahren hat Sigrid Möhl-Wedekind Ende des Jahres 2021 ihr Amt Als Geschäftsführerin altersbedingt niedergelegt.',
    _slug: '2021-nach-20-jahren-hat-sigrid-mohl-wedekind-ende-des-jahres-2021-ihr-amt-als-geschaeftsfuehrerin-altersbedingt-niedergelegt',
    date: 2021,
    showbadge: false
  }
];

class AboutPage extends React.Component {
  state = {
    isOpen: false,
  };

  render = () => (
    <Layout {...this.props}>
      <div>
        <div className="container">
          <Carousel id="carousel-index">
            <Carousel.Item>
              <Img
                alt="bg"
                fluid={this.props.data.about0.childImageSharp.fluid}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Img
                alt="bg"
                fluid={this.props.data.about1.childImageSharp.fluid}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Img
                alt="bg"
                fluid={this.props.data.about2.childImageSharp.fluid}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Img
                alt="bg"
                fluid={this.props.data.about3.childImageSharp.fluid}
              />
            </Carousel.Item>
          </Carousel>

          <section className="row section-margin-bottom">
            <div className="col-sm-6">
              <h1>Familienunternehmen in der 5. Generation</h1>
              <p>
                Mit den Jahren hat sich die 1856 gegründete Möhl GmbH & Co. KG
                von einem Betrieb für die Herstellung feuerfester Erzeugnisse zu
                einer kompetenten Verwaltung der firmeneigenen Immobilien
                gewandelt.
                <br /> Während sich die Kernkompetenz der Firma mit der Zeit
                veränderte, blieb das Unternehmen seinem Sitz in Köln auch über
                die Jahre treu. In Mülheim am Rhein, dem heutigen Köln-Mülheim
                gegründet, erfolgte später der Umzug nach Dellbrück, wo ein
                größeres Grundstück die Möglichkeit eröffnete, auch selbst
                Industrieöfen zu bauen. Der erste große Schritt in ein
                verändertes Geschäftsfeld war damit getan! Gleichzeitig wurden
                die ersten Weichen für den Einstieg in das Immobiliengeschäft
                gestellt.
                <br />
                Der Verkauf der Produktionssparte Industrieofenbau besiegelte
                1996 schließlich den länst begonnenen Weg für die
                Umstrukturierung des produzierenden mittelständischen
                Unternehmens zu einer Immobilienverwaltung.
              </p>
              <div className="blue-line" />
            </div>
            <div className="col-sm-offset-1 col-sm-5">
              <img
                src="https://cms-locomotive.s3.amazonaws.com/sites/55edd7ae218f4fd06b000002/pages/55f1f9cf218f4fd06b000015/files/Bild_Startseite_2.jpg"
                className="img-gallery img-full-width"
                onClick={() => {
                  this.setState({ isOpen: true });
                }}
              />
            </div>
          </section>
          <ul className="timeline">
            {milestones.map(milestone => (
              <li className="timeline-inverted">
                {milestone.showbadge && (
                  <div className="timeline-badge">{milestone.date}</div>
                )}
                <div className="timeline-panel">
                  <div className="timeline-body">
                    <p>
                      {milestone.date}: {milestone.text}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <PhotoSwipe
          isOpen={this.state.isOpen}
          items={gallerie
            .filter(item => item.group === 'Unternehmensgeschichte 2')
            .map(item => {
              return {
                src: item.title,
                w: item.width,
                h: item.height,
                title: item.text,
              };
            })}
          options={{
            // optionName: 'option value'
            // for example:
            index: 0, // start at first slide
            history: false,
            focus: true,
            shareEl: false,

            showAnimationDuration: 2,
            hideAnimationDuration: 2,
            getThumbBoundsFn: function(index) {
              // find thumbnail element
              var thumbnail = document.querySelectorAll('.img-gallery')[0];

              // get window scroll Y
              var pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop;
              // optionally get horizontal scroll

              // get position of element relative to viewport
              var rect = thumbnail.getBoundingClientRect();

              // w = width
              return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
            },
          }}
          onClose={() => this.setState({ isOpen: false })}
        />
      </div>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query {
    about0: file(relativePath: { eq: "about/Bild0.jpg" }) {
      ...fluidImage
    }
    about1: file(relativePath: { eq: "about/Bild1.jpg" }) {
      ...fluidImage
    }
    about2: file(relativePath: { eq: "about/Bild2.jpg" }) {
      ...fluidImage
    }
    about3: file(relativePath: { eq: "about/Bild3.jpg" }) {
      ...fluidImage
    }
  }
`;
